import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { SubjectModel } from '../models/subject.model';
import { PostCodePipe } from '@app/common/pipes/post-code.pipe';


@Pipe({
  name: 'addField'
})
@Injectable({
  providedIn: 'root'
})
export class AddFieldPipe implements PipeTransform {

  transform(src: any, fieldName: string, value: any): any {
    if (value === undefined) {
      return src;
    }
    src[fieldName] = value;
    return src;
  }
}
