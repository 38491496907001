import { Component, OnInit, Inject } from '@angular/core';
import { VfzeImportStepComponent } from '@app/ps/vfze/components/vfze-import-step/vfze-import-step.component';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { PageableList } from '@app/models/pageable.list.ts';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'vfze-import-select',
  templateUrl: './vfze-import-select.component.html',
})
export class VfzeImportSelectComponent extends VfzeImportStepComponent implements OnInit {
  public changeData: any[];
  public isModified: boolean = null;
  public changePageableList: PageableList;
  constructor(
    private restangular: Restangular,
    private errorHandlerService: ErrorHandlerService,
    private pageableListService: PageableListService,
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.restangular.one('vfze/validate-input', this.data.importVfzeId).get().toPromise().then(
      (data) => {
        this.isModified = data.inputModified;
        if (data) {
          this.changePageableList = this.pageableListService.get(
            'vfze/' + this.data.importVfzeId + '/result',
            {
              sortOrder: [{sortBy: 'entityKey'}, {sortBy: 'changeType'}],
            }
          );
          return this.pageChanged(1);
        }
      }, (r) => {
          this.errorHandlerService.get(r);
          this.backCallback.emit();
       }
    );
  }

  pageChanged(pageNumber: number) {
    const promise = this.changePageableList.fetchResults(pageNumber).then((data) => {
      this.changePageableList.list.list
        .filter(item => ['occupation', 'easement', 'construction_object'].includes(item.entityKey) && ['update', 'delete'].includes(item.changeType))
        .forEach(item => {
          item.tooltipData = {filter: {filters: {
            occupationId: item.entityKey === 'occupation' ? item.targetEntityId : undefined,
            easementId: item.entityKey === 'easement' ? item.targetEntityId : undefined,
            constructionObjectId: item.entityKey === 'construction_object' ? item.targetEntityId : undefined,
            cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships'],
          }}};
        })
    });
    this.changePageableList.onListChanged();
    return promise;
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
