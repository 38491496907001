import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StatisticsService } from '@app/ps/services/statistics.service';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { ListService } from '@app/common/services/list.service';
import { StateService, TransitionService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { StatisticsCustomFormComponent } from '@app/ps/components/statistics-custom-form/statistics-custom-form.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'gmt-statistics',
  templateUrl: './statistics.component.html',
})
export class StatisticsComponent implements OnInit, OnDestroy {

  @Input() useStorage = false;
  @Input() showFilter = false;

  activeStatistics = null;
  statisticsList;
  reloadStatisticsList: Function;
  intervalTabs;
  dates;
  selectedStatistics;
  categoryTabs;
  statusDataList;
  currentData;
  filter;
  listChart;
  plotData;
  plotDates;
  yLabels;
  plotLabels;
  loadingFilterData;
  destroyed = false;
  initializing = true;
  drawChart;
  onStart;
  onFinish;
  onSuccess;
  stateParams;

  entityTabs = [
    {
      name: 'Případy',
      id: 'cases',
      params: { statisticsEntityTab: 'cases' },
      sref: 'cases',
      totalCategoryName: 'Celkový počet případů',
    },
    {
      name: 'Osoby',
      id: 'subjects',
      params: { statisticsEntityTab: 'subjects' },
      sref: 'owners',
      totalCategoryName: 'Celkový počet jedinečných osob vlastníků',
    },
    {
      name: 'Listy vlastnictví',
      id: 'titles',
      params: { statisticsEntityTab: 'titles' },
      sref: 'titles',
      totalCategoryName: 'Celkový počet jedinečných listů vlastnictví',
    },
    {
      name: 'Parcely',
      id: 'parcels',
      params: { statisticsEntityTab: 'parcels' },
      sref: 'parcels',
      totalCategoryName: 'Celkový počet jedinečných parcel',
    },
  ];

  filterSettings = [
    { resource: 'construction-objects', paramAttr: 'statisticsConstructionObjectId', filterAttr: 'constructionObjectId', filter: {filters: {validity: 'valid'}, sortOrder: {sortBy: 'socis'}}, globalFilter: undefined },
    { resource: 'construction-object-stages', paramAttr: 'statisticsStageId', filterAttr: 'constructionObjectStageId', filter: {filters: {validity: 'valid'}}, sortOrder: {sortBy: 'name'}, globalFilter: undefined },
    { resource: 'areas', paramAttr: 'statisticsAreaId', filterAttr: 'areaId', filter: {sortOrder: {sortBy: 'name'}}, globalFilter: undefined }
  ];

  constructor(
    private restangular: Restangular,
    private statisticsService: StatisticsService,
    private datePipe: DatePipe,
    private listService: ListService,
    private stateService: StateService,
    private transitionService: TransitionService,
    private dialogService: DialogService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
  ) {
    this.stateParams = this.stateService.params;
    this.statisticsChanged = this.statisticsChanged.bind(this);
    this.reloadStatisticsSelectRegister = this.reloadStatisticsSelectRegister.bind(this);
    this.removeStatisticsFilter = this.removeStatisticsFilter.bind(this);
    this.addToHome = this.addToHome.bind(this);
    this.ngOnInit = this.ngOnInit.bind(this);
    this.registerCallback = this.registerCallback.bind(this);
  }

  private getDefault(valueKey) {
    return this.useStorage
      ? this.statisticsService.getStorageKeyValue(valueKey)
      : this.statisticsService.getDefaultKeyValue(valueKey);
  }

  ngOnInit() {
    this.statisticsList = this.statisticsService.getStatisticsFilters();
    let currentParams: any = this.stateParams;
    this.filter = {};

    Promise.all([
      this.loadActiveStatistics(this.getActiveStatisticsId() || this.getDefault('statisticsId')),
      this.statisticsList.promise
    ]).then((data) => {
      if (this.destroyed || !this.statisticsList.list.length) {
        this.initializing = false;
        return;
      }

      /**
       * Update data according to parameters
       */
      this.onFinish = this.transitionService.onFinish({ to: this.stateService.current.name }, (trans) => {
        if (
          ((trans.params('to').statisticsCategoryTab && currentParams.statisticsCategoryTab !== trans.params('to').statisticsCategoryTab) ||
            (trans.params('to').statisticsEntityTab && currentParams.statisticsEntityTab !== trans.params('to').statisticsEntityTab)) &&
          this.plotData
        ) {
          this.drawChart(
            trans.params('to').statisticsEntityTab,
            trans.params('to').statisticsCategoryTab
          );
        }

        if (
          (trans.params('to').statisticsStatistics && (!this.statusDataList || currentParams.statisticsStatistics !== trans.params('to').statisticsStatistics)) ||
          currentParams.statisticsConstructionObjectId !== trans.params('to').statisticsConstructionObjectId ||
          currentParams.statisticsStageId !== trans.params('to').statisticsStageId ||
          currentParams.statisticsAreaId !== trans.params('to').statisticsAreaId
        ) {
          this.loadDefaultFilterData(trans.params('to'));
          this.fetchStatusData();
          this.setTotalCategoryName(trans.params('to').statisticsEntityTab);
        }

        const differentPlotData = (trans.params('to').statisticsStatistics && currentParams.statisticsStatistics !== trans.params('to').statisticsStatistics) ||
          currentParams.statisticsConstructionObjectId !== trans.params('to').statisticsConstructionObjectId ||
          currentParams.statisticsStageId !== trans.params('to').statisticsStageId ||
          currentParams.statisticsAreaId !== trans.params('to').statisticsAreaId ||
          (trans.params('to').statisticsIntervalTab !== undefined && currentParams.statisticsIntervalTab !== trans.params('to').statisticsIntervalTab);

        if (differentPlotData || !this.plotData) {
          this.fetchPlotData(!differentPlotData)
            .then(() => {
              params = this.testDrawChartParams(trans.params('to').statisticsEntityTab, trans.params('to').statisticsCategoryTab);
              if (params) {
                this.stateService.go('.', params);
              } else {
                this.drawChart(
                  trans.params('to').statisticsEntityTab,
                  trans.params('to').statisticsCategoryTab
                );
              }
            })
            .catch(() => {});
        }
        currentParams = trans.params('to');

        if (this.useStorage) {
          this.statisticsService.updateStorageValue({
            statisticsId: trans.params('to').statisticsStatistics || this.getActiveStatisticsId(),
            intervalTab: trans.params('to').statisticsIntervalTab !== undefined ? trans.params('to').statisticsIntervalTab : this.getActiveIntervalTab(),
            entityTab: trans.params('to').statisticsEntityTab || this.getActiveEntityTab(),
            categoryTab: trans.params('to').statisticsCategoryTab || this.getActiveCategoryTab(),
          });
        }
      });

      /**
       * Check parameters or redirect if necessary
       */
      this.onStart = this.transitionService.onStart({ to: this.stateService.current.name }, (trans) => {
        if (trans.params('to').projectKey !== trans.params('from').projectKey) {
          return;
        }
        const promises = [];
        if (
          (trans.params('to').statisticsCategoryTab && currentParams.statisticsCategoryTab !== trans.params('to').statisticsCategoryTab) ||
          (trans.params('to').statisticsEntityTab && currentParams.statisticsEntityTab !== trans.params('to').statisticsEntityTab)
        ) {
          params = this.testDrawChartParams(
            trans.params('to').statisticsEntityTab,
            trans.params('to').statisticsCategoryTab
          );

          if (params) {
            promises.push(Promise.resolve(params));
          }
        }
        if (trans.params('to').statisticsStatistics && currentParams.statisticsStatistics !== trans.params('to').statisticsStatistics) {
          promises.push(this.loadActiveStatistics(trans.params('to').statisticsStatistics).then((stat) => {
            if (stat) {
              params = this.setActiveStatistics(stat);
              if (!_.isEmpty(params)) {
                return params;
              }
            } else {
              this.setActiveStatistics(null);
              return Promise.reject('Statistics does not exist');
            }
          }));
        }
        if (trans.params('to').statisticsStatistics && (!this.intervalTabs || currentParams.statisticsStatistics !== trans.params('to').statisticsStatistics)) {
          this.updateIntervalTabs();
        }
        if (
          (trans.params('to').statisticsIntervalTab !== undefined && currentParams.statisticsIntervalTab !== trans.params('to').statisticsIntervalTab) ||
          (trans.params('to').statisticsStatistics && (!this.dates || currentParams.statisticsStatistics !== trans.params('to').statisticsStatistics))
        ) {
          params = this.updateDates(trans.params('to').statisticsIntervalTab);
          if (params) {
            promises.push(Promise.resolve(params));
            return this.stateService.target(trans.to(), _.assign({}, trans.params('to'), params));
          }
        }

        return Promise.all(promises).then((paramsList) => {
          params = _.reduce(paramsList, (acc, params) => !_.isEmpty(params) ? _.assign(acc, params) : acc, {});
          if (!_.isEmpty(params)) {
            return this.stateService.target(trans.to(), _.assign({}, trans.params('to'), params));
          }
        });
      });

      let stat = data[0];
      if (!stat && this.statisticsList.list.length) {
        stat = this.statisticsList.list[0];
      }

      let params = _.assign(
        this.setActiveStatistics(stat),
        this.setActiveEntityTab(this.getActiveEntityTab() || this.getDefault('entityTab')),
        this.setActiveCategoryTab(this.getActiveCategoryTab() || this.getDefault('categoryTab')),
        this.setActiveIntervalTab(this.getActiveIntervalTab() || this.getDefault('intervalTab'))
      );
      this.loadDefaultFilterData(currentParams).then(() => {
        this.initializing = false;
        if (_.isEmpty(params)) {
          this.updateIntervalTabs();
          params = this.updateDates(this.getActiveIntervalTab());
        }
        if (_.isEmpty(params)) {
          this.fetchStatusData();
          this.setTotalCategoryName(this.getActiveEntityTab());
          this.fetchPlotData().then(() => {
            params = this.testDrawChartParams(this.getActiveEntityTab(), this.getActiveCategoryTab());
            if (params) {
              this.stateService.go('.', params);
            } else {
              this.drawChart(this.getActiveEntityTab(), this.getActiveCategoryTab());
            }
          });
        } else {
          this.stateService.go('.', params);
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroyed = true;
    if (this.onStart) {
      this.onStart();
    }
    if (this.onFinish) {
      this.onFinish();
    }
    if (this.onSuccess) {
      this.onSuccess();
    }
    if (this.listChart) {
      this.listService.cancelFetch(this.listChart);
    }
    if (this.statusDataList) {
      this.listService.cancelFetch(this.statusDataList);
    }
  }

  reloadStatisticsSelectRegister(reload) {
    this.reloadStatisticsList = reload;
  }

  setActiveStatistics(statistics) {
    if (statistics && !this.isActiveStatisticsId(statistics.id)) {
      this.activeStatistics = statistics;
      this.selectedStatistics = this.activeStatistics;
      if (this.activeStatistics && this.getActiveStatisticsId() !== this.activeStatistics.id) {
        return {
          statisticsStatistics: this.activeStatistics ? this.activeStatistics.id : null,
          statisticsConstructionObjectId: this.activeStatistics.constructionObjectId,
          statisticsStageId: this.activeStatistics.constructionObjectStageId,
          statisticsAreaId: this.activeStatistics.areaId,
        };
      }
    } else if (!statistics) {
      this.selectedStatistics = this.activeStatistics = null;
    }
    return {};
  }

  setActiveEntityTab(tab) {
    if (!this.isActiveEntityTab(tab)) {
      return {statisticsEntityTab: tab};
    }
    return {};
  }

  setActiveCategoryTab(tab) {
    if (!this.isActiveCategoryTab(tab)) {
      return {statisticsCategoryTab: tab};
    }
    return {};
  }

  setActiveIntervalTab(tab) {
    if (!this.isActiveIntervalTab(tab)) {
      return { statisticsIntervalTab: tab };
    }
    return {};
  }

  updateIntervalTabs() {
    this.intervalTabs = _.map(this.activeStatistics.intervals, (intervalSetting, index) => {
      return {
        name: intervalSetting.name,
        id: index,
        pointCount: intervalSetting.pointCount,
        pointInterval: intervalSetting.pointInterval,
        params: { statisticsIntervalTab: index },
      };
    });
  }

  updateDates(intervalTabId) {
    const curDate = new Date();
    const intervalTab = _.find(this.intervalTabs, {id: intervalTabId});
    this.dates = [];
    if (!intervalTab) {
      if (intervalTabId) {
        return { statisticsIntervalTab: 0 };
      }
      return;
    }
    while (this.dates.length < intervalTab.pointCount) {
      this.dates.push(this.datePipe.transform(curDate, 'y-MM-dd'));

      curDate.setDate(curDate.getDate() - intervalTab.pointInterval);
    }
  }

  setTotalCategoryName(activeEntityTab) {
    const tab = this.entityTabs.find((entityTab) => entityTab.id === activeEntityTab);
    if (tab && this.categoryTabs) {
      this.categoryTabs[0].name = tab.totalCategoryName;
    }
  }

  fetchStatusData() {
    if (this.statusDataList) {
      this.listService.cancelFetch(this.statusDataList);
    }
    this.currentData = null;
    this.categoryTabs = [];
    this.activeStatistics.categories.forEach(tab => {
      this.categoryTabs.push({
        name: tab.name,
        splitTab: tab.split,
      });
      if (tab.split) {
        this.categoryTabs.push({
          name: tab.splitName,
        });
      }
    });
    this.categoryTabs.unshift({
      name: this.activeStatistics.defaultCategory,
    });
    this.categoryTabs.unshift({
      name: null,
    });

    const datesForTrend = [this.dates[0]];

    this.intervalTabs.forEach((intervalTab) => {
      intervalTab.changeValues = {};
      intervalTab.trends = {};
      const curDate = new Date();
      intervalTab.trendDates = [this.dates[0]];
      for (let i = 2; i > 0; i--) {
        curDate.setDate(curDate.getDate() - (intervalTab.pointInterval * (intervalTab.pointCount - 1)));
        const dateString = this.datePipe.transform(curDate, 'y-MM-dd');
        if (!_.includes(datesForTrend, dateString)) {
          datesForTrend.push(dateString);
        }
        intervalTab.trendDates.push(dateString);
      }
    });

    this.statusDataList = this.listService.createList('statistics/generic', {
      filters: {
        dateTo: datesForTrend,
        areaId: this.filter.areaId ? [this.filter.areaId] : [],
        constructionObjectId: this.filter.constructionObjectId ? [this.filter.constructionObjectId] : [],
        constructionObjectStageId: this.filter.constructionObjectStageId ? [this.filter.constructionObjectStageId] : [],
        statisticsId : [this.activeStatistics.id],
      },
    });

    return this.listService.fetchResult(this.statusDataList).then((data) => {
      const currentStatuses = data.list[0].plot[datesForTrend[0]];
      this.currentData = _.keyBy(
        _.map(this.entityTabs, (tab) => {
          const dataR = {
            id: tab.id,
            data: [],
          };
          let index = 0;
          data.list[0].categories.forEach((categorySetting, categoryIndex) => {
            const category = _.find(currentStatuses, {status: categoryIndex + 1});

            dataR.data.push({
              value: currentStatuses && category ? category[tab.id + 'InStatus'] : 0,
              url: tab.sref && (tab.id !== 'cases' || index !== 0) ? this.stateService.href('symap.project.' + tab.sref, {
                sef: category ? category.entityFilterKey + (categorySetting.split ? '_0' : '') : null,
              }) : null,
            });
            index++;

            if (categorySetting.split) {
              dataR.data.push({
                value: currentStatuses && category ? (tab.id === 'cases' ? category['casesInStatus'] : category[tab.id + 'OnlyInStatus']) : 0,
                url: tab.sref && (tab.id !== 'cases' || index !== 0) ? this.stateService.href('symap.project.' + tab.sref, {
                  sef: category ? category.entityFilterKey + '_1' : null,
                }) : null,
              });

              dataR.data[dataR.data.length - 2].value = dataR.data[dataR.data.length - 2].value - dataR.data[dataR.data.length-1].value
              index++;
            }
          });
          const totalStatus = currentStatuses ? _.find(currentStatuses, {status: 0}) : null;
          dataR.data.unshift({
            value: totalStatus ? totalStatus[tab.id] : 0,
            url: totalStatus && tab.sref && tab.id !== 'cases' ? this.stateService.href('symap.project.' + tab.sref, {
              sef: totalStatus.entityFilterKey,
            }) : null,
          });
          return dataR;
        }),
        (tabData) => tabData.id
      );

      this.intervalTabs.forEach((intervalTab) => {
        this.entityTabs.forEach((entity) => {
          const changeValues = intervalTab.changeValues[entity.id] = [];
          const trends = intervalTab.trends[entity.id] = [];

          // let categoryIndex = 0;
          data.list[0].categories.forEach((categorySetting, index) => {
            const startTotal = _.find(data.list[0].plot[intervalTab.trendDates[0]], { status: 0 });
            const startCategory = _.find(data.list[0].plot[intervalTab.trendDates[0]], { status: index + 1 });
            const middleTotal = _.find(data.list[0].plot[intervalTab.trendDates[1]], { status: 0 });
            const middleCategory = _.find(data.list[0].plot[intervalTab.trendDates[1]], { status: index + 1 });
            const endTotal = _.find(data.list[0].plot[intervalTab.trendDates[2]], { status: 0 });
            const endCategory = _.find(data.list[0].plot[intervalTab.trendDates[2]], { status: index + 1 });

            const currentChangeValue = Math.abs(
              (startCategory ? startCategory[entity.id] : (categorySetting.graphType === 'INCREASING' ? 0 : startTotal[entity.id])) -
              (middleCategory ? middleCategory[entity.id] : (categorySetting.graphType === 'INCREASING' ? 0 : middleTotal[entity.id])));

            const prevInterval = Math.abs(
              (middleCategory ? middleCategory[entity.id] : (categorySetting.graphType === 'INCREASING' ? 0 : middleTotal[entity.id])) -
              (endCategory ? endCategory[entity.id] : (categorySetting.graphType === 'INCREASING' ? 0 : endTotal[entity.id])));

            changeValues.push(currentChangeValue);
            trends.push(
              prevInterval !== 0 ? Math.round((currentChangeValue - prevInterval) / prevInterval * 100) : (middleCategory !== undefined ? 0 : null)
            );
            if (categorySetting.split) {
              const attribute = entity.id + 'PassedStatus';
              const currentChangeValue = Math.abs(
                (startCategory ? startCategory[attribute] : (categorySetting.splitGraphType === 'INCREASING' ? 0 : startTotal[entity.id])) -
                (middleCategory ? middleCategory[attribute] : (categorySetting.splitGraphType === 'INCREASING' ? 0 : middleTotal[entity.id])));

              const prevInterval = Math.abs(
                (middleCategory ? middleCategory[attribute] : (categorySetting.splitGraphType === 'INCREASING' ? 0 : middleTotal[entity.id])) -
                (endCategory ? endCategory[attribute] : (categorySetting.splitGraphType === 'INCREASING' ? 0 : endTotal[entity.id])));

              changeValues.push(currentChangeValue);
              trends.push(
                prevInterval !== 0 ? Math.round((currentChangeValue - prevInterval) / prevInterval * 100) : (middleCategory !== undefined ? 0 : null)
              );
            }
          });
        });
      });
    }).catch(() => {});
  }

  fetchPlotData(chainDataPromise = false) {
    let fetchPlotDataChainPromiseResolution = (val) => {};
    const tmpFetchPlotDataChainPromiseResolution = fetchPlotDataChainPromiseResolution;
    let tmpFetchPlotDataChainDefered: any = {};
    const promise = new Promise((resolve, reject) => {
      tmpFetchPlotDataChainDefered = {
        resolve: resolve,
        reject: reject
      };
    });
    tmpFetchPlotDataChainDefered.promise = promise;
    const promiseChainHandler = () => tmpFetchPlotDataChainDefered.promise.then(result => result ? Promise.resolve() : Promise.reject());
    fetchPlotDataChainPromiseResolution = tmpFetchPlotDataChainDefered.resolve;
    if (this.listChart) {
      // break the chain of promises to allow to draw updated plot
      if (chainDataPromise) {
        tmpFetchPlotDataChainPromiseResolution(false);
        return this.listChart.promise.then(promiseChainHandler);
      } else {
        this.listService.cancelFetch(this.listChart);
      }
    }
    this.listChart = this.listService.createList('statistics/generic', {
      filters: {
        dateTo: this.dates,
        areaId: this.filter.areaId ? [this.filter.areaId] : [],
        constructionObjectId: this.filter.constructionObjectId ? [this.filter.constructionObjectId] : [],
        constructionObjectStageId: this.filter.constructionObjectStageId ? [this.filter.constructionObjectStageId] : [],
        statisticsId : [this.activeStatistics.id],
      },
    });

    delete this.plotData;
    return this.listService.fetchResult(this.listChart)
      .then((data) => {
        this.plotDates = _.sortBy(Object.keys(data.list[0].plot), (date) => {
          return new Date(date).getTime();
        });
        this.plotData = {};
        this.entityTabs.forEach((entity) => {
          this.plotData[entity.id] = [];
          data.list[0].categories.forEach((categorySetting, index) => {
            this.plotData[entity.id].push(_.map(this.plotDates,
              (date, dateIndex) => {
                const total =  _.find(data.list[0].plot[date], {status: 0})[entity.id];
                const category = _.find(data.list[0].plot[date], {status: index + 1});
                const value = category ? category[entity.id] : (categorySetting.graphType === 'INCREASING' ? 0 : total);

                return {
                  x: dateIndex,
                  y: total > 0 ? value / total : null,
                  total: total,
                  value: value,
                };
              }));
            if (categorySetting.split) {
              this.plotData[entity.id].push(_.map(this.plotDates,
                (date, dateIndex) => {
                  const total =  _.find(data.list[0].plot[date], { status: 0 })[entity.id];
                  const category = _.find(data.list[0].plot[date], { status: index + 1 });
                  const value = category ? category[entity.id + 'PassedStatus'] : (categorySetting.splitGraphType === 'INCREASING' ? 0 : total);
                  return {
                    x: dateIndex,
                    y: total > 0 ? value / total : null,
                    total: total,
                    value: value,
                  };
                }));
            }
          });
        });
        this.yLabels = [];
        data.list[0].categories.forEach((cat) => {
          this.yLabels.push(cat);
          if (cat.split) {
            this.yLabels.push(Object.assign({}, cat, { yAxisLabel: cat.splitYAxisLabel }));
          }
        });
        this.plotLabels = _.map(this.plotDates, (time) => this.datePipe.transform(new Date(time)));
        fetchPlotDataChainPromiseResolution(true);
      })
      .then(promiseChainHandler);
  }

  getActiveEntityTab() {
    return this.stateParams.statisticsEntityTab;
  }

  getActiveCategoryTab() {
    if (this.isActiveEntityTab('cases') && this.categoryTabs && this.categoryTabs[this.stateParams.statisticsCategoryTab] && this.categoryTabs[this.stateParams.statisticsCategoryTab].splitTab) {
      return this.stateParams.statisticsCategoryTab + 1;
    }
    return this.stateParams.statisticsCategoryTab;
  }

  getActiveIntervalTab() {
    return this.stateParams.statisticsIntervalTab;
  }

  getActiveStatisticsId() {
     return this.stateParams.statisticsStatistics;
  }

  isActiveEntityTab(tab) {
    return this.getActiveEntityTab() === tab;
  }

  isActiveCategoryTab(tab) {
    return this.getActiveCategoryTab() === tab;
  }

  isActiveIntervalTab(tab) {
    return this.getActiveIntervalTab() === tab;
  }

  isActiveStatisticsId(id) {
    return this.activeStatistics && this.activeStatistics.id === id;
  }

  loadDefaultFilterData(currentParams) {
    this.loadingFilterData = true;
    const promises = _.map(this.filterSettings, (resDef) => {
      let stateParamValue = currentParams ? currentParams[resDef.paramAttr] : null;
      if (!this.showFilter && this.activeStatistics) {
        stateParamValue = this.activeStatistics[resDef.filterAttr];
      }

      if (stateParamValue && (!this.filter[resDef.filterAttr] || this.filter[resDef.filterAttr].id !== stateParamValue)) {
        this.filter[resDef.filterAttr] = {id: stateParamValue};
        const idsLoadList = this.listService.createList(resDef.resource, {
          entityIds: [stateParamValue],
          limit: 1,
        });
        return this.listService.fetchResult(idsLoadList).then(() => {
          this.filter[resDef.filterAttr] = idsLoadList.list.length ? idsLoadList.list[0] : null;
        });
      } else {
        if (!stateParamValue) {
          this.filter[resDef.filterAttr] = null;
        }
        return Promise.resolve();
      }
    });
    this.refreshGlobalFilter();
    return Promise.all(promises).then(() => {this.loadingFilterData = false;});
  }

  loadActiveStatistics(id) {
    if (!id) {
      return Promise.resolve(null);
    }
    if (this.activeStatistics && this.activeStatistics.id === id) {
      return Promise.resolve(this.activeStatistics);
    }
    const idsLoadList = this.listService.createList('statistics/filters', {
      filters: { entityId: [id], available: true },
      limit: 1,
    });
    return this.listService.fetchResult(idsLoadList).then(() => {
      return idsLoadList.list[0];
    });
  }

  registerCallback(drawChart) {
    this.drawChart = (statisticsEntityTab, statisticsCategoryTab) => {
      this.setTotalCategoryName(statisticsEntityTab);
      if (this.plotData) {
        statisticsCategoryTab = statisticsEntityTab !== 'cases' || !this.categoryTabs[statisticsCategoryTab].splitTab ? statisticsCategoryTab : statisticsCategoryTab + 1;
        drawChart(this.plotData[statisticsEntityTab][statisticsCategoryTab - 1], this.plotLabels, this.yLabels[statisticsCategoryTab - 1]);
      }
    };
  }

  testDrawChartParams(statisticsEntityTab, statisticsCategoryTab) {
    this.setTotalCategoryName(statisticsEntityTab);
    if (this.plotData) {
      if (!this.plotData[statisticsEntityTab]) {
        return { statisticsEntityTab: 'cases' };
      }
      if (!this.plotData[statisticsEntityTab][statisticsCategoryTab - 1] && statisticsCategoryTab > 1) {
        return { statisticsCategoryTab: 1 };
      }
    }
  }

  refreshGlobalFilter() {
    const globalFilter = {
      areaId: this.filter.areaId ? [this.filter.areaId] : [],
      constructionObjectId: this.filter.constructionObjectId ? [this.filter.constructionObjectId] : [],
      constructionObjectStageId: this.filter.constructionObjectStageId ? [this.filter.constructionObjectStageId] : [],
    };

    this.filterSettings[0].globalFilter = {
      areaId: globalFilter.areaId,
      constructionObjectStageId: globalFilter.constructionObjectStageId,
    };

    this.filterSettings[1].globalFilter = {
      areaId: globalFilter.areaId,
      constructionObjectId: globalFilter.constructionObjectId,
    };

    this.filterSettings[2].globalFilter = {
      constructionObjectId: globalFilter.constructionObjectId,
      constructionObjectStageId: globalFilter.constructionObjectStageId,
    };
  }

  statisticsChanged(newValue) {
    const params = _.assign({
      statisticsCategoryTab: 1,
      statisticsIntervalTab: 0,
      statisticsConstructionObjectId: this.filter.constructionObjectId ? this.filter.constructionObjectId.id : null,
      statisticsStageId: this.filter.constructionObjectStageId ? this.filter.constructionObjectStageId.id : null,
      statisticsAreaId: this.filter.areaId ? this.filter.areaId.id : null,
    }, this.setActiveStatistics(newValue));
    this.stateService.go('.', params);
    this.refreshGlobalFilter();
  }

  isFiltered() {
    return _.some(this.filterSettings, (item) => (this.filter[item.filterAttr] ? this.filter[item.filterAttr].id : null) !== this.activeStatistics[item.filterAttr]) &&
      _.some(this.filterSettings, (item) => this.filter[item.filterAttr]);
  }

  isDefaultStatistics() {
    return !_.some(this.filterSettings, (item) => this.activeStatistics[item.filterAttr]);
  }

  addToHome() {
    const dialog = this.dialogService.open(StatisticsCustomFormComponent, {
      data: {
        statistics: this.activeStatistics,
        filter: {
          areaId: this.filter.areaId ? this.filter.areaId.id : null,
          constructionObjectId: this.filter.constructionObjectId ? this.filter.constructionObjectId.id : null,
          constructionObjectStageId: this.filter.constructionObjectStageId ? this.filter.constructionObjectStageId.id : null,
        }
      },
      className: ClassName.HIGHER_DIALOG
    });

    const sub = dialog.afterClosed.subscribe(result => {
      if (result) {
        this.statisticsService.removeLoadedStatisticsFilters();
        this.reloadStatisticsList();
        const params = this.setActiveStatistics(result);
        this.stateService.go('.', params);
      }

      sub.unsubscribe();
    });
  }

  removeStatisticsFilter() {
    const statId = this.activeStatistics.id;
    return this.restangular.all('statistics').one('filters', this.activeStatistics.id).remove().toPromise().then(() => {
      this.statisticsService.removeLoadedStatisticsFilters();
      this.reloadStatisticsList().then((list) => {
        if (list.list.length && (this.activeStatistics.id === statId)) {
          const params = this.setActiveStatistics(list.list[0]);
          this.stateService.go('.', params);
        }
      });
    });
  }
}
