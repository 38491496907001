import { Component, OnInit, Inject } from '@angular/core';
import { VfzeImportStepComponent } from '@app/ps/vfze/components/vfze-import-step/vfze-import-step.component';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'vfze-import-import',
  templateUrl: './vfze-import-import.component.html',
})
export class VfzeImportImportComponent extends VfzeImportStepComponent implements OnInit {
  public imported = false;

  constructor(
    private restangular: Restangular,
    private errorHandlerService: ErrorHandlerService,
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.restangular.one('vfze/import-confirm', this.data.importVfzeId).get().toPromise().then(
      (data) => {
        this.imported = true;
      }, (r) => {
          this.errorHandlerService.get(r);
          this.backCallback.emit();
      }
    )
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
