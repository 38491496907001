import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';
import * as d3tip from 'd3-tip';
import * as _ from 'lodash';

@Component({
  selector: 'gmt-line-chart',
  template: '<div></div>',
})
export class LineChartComponent implements OnInit, OnDestroy {

  @Output() callback = new EventEmitter();

  svg;
  marginContainer;
  plotData;
  plotLabels;
  categorySetting;
  width;

  constructor(
    private elementRef: ElementRef,
  ) {
    this.drawChart = this.drawChart.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  ngOnInit() {
    this.init();
    this.callback.emit({
      drawChart: this.drawChart,
    });
  }

  ngOnDestroy() {
    this.tip.destroy();
    $(window).off('resize', this.onResize);
  }

  tip = d3tip()
    .attr('class', 'd3-tip')
    .offset([-10, 0])
    .html((d) =>
      '<div>' + this.categorySetting.valueLabel + ' ' + d3.format('.1%')(d.y) + ' (' + d.value + ' z ' + d.total + ')</div>' +
      '<div>' + this.categorySetting.remainLabel + ' ' + d3.format('.1%')(1 - d.y) + ' (' + (d.total - d.value) + ' z ' + d.total + ')</div>'
    );

  init() {
    this.svg = d3.select(this.elementRef.nativeElement.children[0]).append('svg').attr('height', '0');
    this.marginContainer = this.svg.append('g');
  }

  getDimensions() {
    const margin = {top: 40, right: 50, bottom: 40, left: 100};
    // 15 is reserved for scrollbar
    let width = this.elementRef.nativeElement.children[0].clientWidth - margin.left - margin.right - 15;
    // multiples on 20
    width = Math.floor(width / 10) * 10;
    return {
      margin: margin,
      width: width, // Use the window's width
      height: 250, // - margin.top - margin.bottom; // Use the window's height
    };
  }

  resize(dimensions) {
    this.svg
      .attr('viewBox', '0 0 ' + (dimensions.width + dimensions.margin.left + dimensions.margin.right) + ' ' + (dimensions.height + dimensions.margin.top + dimensions.margin.bottom))
      .attr('height', dimensions.height + dimensions.margin.top + dimensions.margin.bottom)
      .attr('width', dimensions.width + dimensions.margin.left + dimensions.margin.right);

    this.marginContainer
      .attr('transform', 'translate(' + dimensions.margin.left + ',' + dimensions.margin.top + ')');
  }

  xAxis(xScale) {
    return d3.axisBottom(xScale)
      .ticks(this.plotData.length)
      .tickFormat((x) => this.plotLabels[x]);
  }

  xGrid(xScale, height) {
    return d3.axisBottom(xScale)
      .ticks(this.plotData.length - 1)
      .tickSize(-height)
      .tickFormat('');
  }

  yAxis(yScale) {
    return d3.axisLeft(yScale)
      .ticks(10)
      .tickFormat((y) => d3.format('.0p')(y));
  }

  yGrid(yScale, width) {
    return d3.axisLeft(yScale)
      .ticks(10)
      .tickSize(-width)
      .tickFormat('');
  }

  removeOverlappingLabels(dateTicks) {
    for (let j = 0; j < dateTicks.length; j++) {
      const c = dateTicks[j];
      let n = dateTicks[j + 1];
      if (!c || !n || !c.getBoundingClientRect || !n.getBoundingClientRect) {
        continue;
      }
      while (c.getBoundingClientRect().right + 5 > n.getBoundingClientRect().left) {
        d3.select(n).remove();
        j++;
        n = dateTicks[j + 1];
        if (!n) {
          break;
        }
      }
    }
  }

  drawChart(plotData, plotLabels, categorySetting) {
    this.categorySetting = categorySetting;
    if (this.plotData) {
      this.updateChart(plotData, plotLabels, categorySetting);
      return;
    }
    this.plotData = plotData;
    this.plotLabels = plotLabels;
    // 2. Use the margin convention practice
    const dimensions = this.getDimensions();
    this.width = dimensions.width;
    this.resize(dimensions);

    // The number of datapoints
    const n = this.plotData.length;

    // 5. X scale will use the index of our data
    const xScale = d3.scaleLinear()
      .domain([0, n - 1]) // input
      .range([0, dimensions.width]); // output

    // 6. Y scale will use the randomly generate number
    const yScale = d3.scaleLinear()
      .domain([0, 1]) // input
      .range([dimensions.height, 0]); // output

    // 7. d3's line generator
    const line = d3.line()
      .x((d, i) => { return xScale(i); }) // set the x values for the line generator
      .y((d) => { return yScale(d.y); }) // set the y values for the line generator
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    const area = d3.area()
      .x((d, i) => { return xScale(i); })
      .y0(dimensions.height)
      .y1((d) => { return yScale(d.y); })
      .curve(d3.curveMonotoneX);

    // 8. An array of objects of length N. Each object has key -> value pair, the key being 'y' and the value is a random number
    const dataset = this.plotData;
    const startDataset = _.map(this.plotData, (plot) => { return { x: plot.x, y: 0 }; });
    // 1. Add the SVG to the page and employ #2
    this.marginContainer.call(this.tip);

    // add the X gridlines
    this.marginContainer.append('g')
      .attr('class', 'x grid')
      .attr('transform', 'translate(0,' + dimensions.height + ')')
      .call(this.xGrid(xScale, dimensions.height));

    // add the Y gridlines
    this.marginContainer.append('g')
      .attr('class', 'y grid')
      .call(this.yGrid(yScale, dimensions.width));

    // 3. Call the x axis in a group tag
    const el = this.marginContainer.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + dimensions.height + ')')
      .call(this.xAxis(xScale));
    el.selectAll('text')
      .attr('y', 20);
    const dateTicks = el.selectAll('.tick').nodes();
    this.removeOverlappingLabels(dateTicks);

    // 4. Call the y axis in a group tag
    this.marginContainer.append('g')
      .attr('class', 'y axis')
      .call(this.yAxis(yScale))
      .selectAll('text')
      .attr('x', -30); // Create an axis component with d3.axisLeft

    this.marginContainer.append('text')
      .attr('class', 'y label')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('x', -dimensions.height / 2)
      .attr('dy', '-80')
      .style('text-anchor', 'middle')
      .text(categorySetting.yAxisLabel);

    // set the gradient
    this.marginContainer.append('linearGradient')
      .attr('id', 'area-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0).attr('y1', yScale(0))
      .attr('x2', 0).attr('y2', yScale(1))
      .selectAll('stop')
      .data([
        { offset: '0%', color: '#0096dc', opacity: 0.01 },
        { offset: '100%', color: '#0096dc', opacity: 0.8 }
      ])
      .enter().append('stop')
      .attr('opacity', (d) => { return d.opacity; })
      .attr('offset', (d) => { return d.offset; })
      .attr('stop-color', (d) => { return d.color; })
      .attr('stop-opacity', (d) => { return d.opacity; });

    // 9. Append the path, bind the data, and call the line generator
    this.marginContainer.append('path')
      .datum(dataset) // 10. Binds data to the line
      .attr('class', 'line') // Assign a class for styling
      .attr('d', line) // 11. Calls the line generator
      .transition()
      .duration(500)
      .ease(d3.easeQuad)
      .attrTween('d', () => {
        const interpolator = d3.interpolateArray(startDataset, dataset);
        return (t) => line(interpolator(t));
      })
      .on('end', () => this.drawCircles(xScale, yScale, dataset));

    this.marginContainer.append('path')
      .datum(startDataset)
      .attr('class', 'area')
      .attr('d', area)
      .transition()
      .duration(500)
      .ease(d3.easeQuad)
      .attrTween('d', () => {
        const interpolator = d3.interpolateArray(startDataset, dataset);
        return (t) => area(interpolator(t));
      });

    $(window).on('resize', this.onResize);
  }

  drawCircles(xScale, yScale, dataset) {
    if (dataset !== this.plotData) {
      return;
    }
    const circleContainer = this.marginContainer.append('g').attr('class', 'circles');
    // 12. Appends a circle for each datapoint
    dataset.forEach((d, index) => {
        circleContainer
          .datum(d)
          .append('circle') // Uses the enter().append() method
          .attr('class', 'dot') // Assign a class for styling
          .attr('cx', xScale(d.x))
          .attr('cy', yScale(d.y))
          .attr('r', 0)
          .transition()
          .delay(100 * index)
          .duration(750)
          .ease(d3.easeElastic, 1.5, .75)
          .attr('r', 7);

        const tip = this.tip;
        circleContainer
          .datum(d)
          .append('circle') // Uses the enter().append() method
          .attr('class', 'dotHover') // Assign a class for styling
          .attr('cx', xScale(d.x))
          .attr('cy', yScale(d.y))
          .attr('opacity', 0.01)
          .attr('r', 8)
          .on('mouseenter', function (d) {
            tip.show(d);
            d3.select(this)
              .transition().attr('opacity', 1);
          })
          .on('mouseleave', function () {
            tip.hide();
            d3.select(this)
              .transition().attr('opacity', 0.01);
          });
      }
    );
  }

  updateChart(plotData, plotLabels, categorySetting) {
    this.plotData = plotData;
    this.plotLabels = plotLabels;
    const dataset = this.plotData;
    const startDataset = _.map(this.plotData, (plot) => { return {x: plot.x, y: 0}; });
    const dimensions = this.getDimensions();
    this.width = dimensions.width;
    this.resize(dimensions);
    const n = this.plotData.length;

    const xScale = d3.scaleLinear()
      .domain([0, n - 1])
      .range([0, dimensions.width]);

    const yScale = d3.scaleLinear()
      .domain([0, 1]) // input
      .range([dimensions.height, 0]); // output

    const line = d3.line()
      .x((d, i) => { return xScale(i); })
      .y((d) => { return yScale(d.y); })
      .curve(d3.curveMonotoneX);

    const area = d3.area()
      .x((d, i) => { return xScale(i); })
      .y0(dimensions.height)
      .y1((d) => { return yScale(d.y); })
      .curve(d3.curveMonotoneX);

    this.marginContainer.select('.x.grid')
      .transition()
      .call(this.xGrid(xScale, dimensions.height));

    this.marginContainer.select('.y.grid')
      .transition()
      .call(this.yGrid(yScale, dimensions.width));

    const el = this.marginContainer.select('.x.axis')
      .call(this.xAxis(xScale));
    el.selectAll('text').attr('y', 20);
    const dateTicks = el.selectAll('.tick').nodes();
    this.removeOverlappingLabels(dateTicks);

    this.marginContainer.select('.y.axis')
      .transition()
      .call(this.yAxis(yScale))
      .selectAll('text')
      .attr('x', -30);

    this.marginContainer.select('.y.label')
      .text(categorySetting.yAxisLabel);

    this.marginContainer.select('.circles').remove();
    this.marginContainer.select('.line')
      .transition()
      .duration(500)
      .ease(d3.easeQuad)
      .attrTween('d', () => {
        const interpolator = d3.interpolateArray(startDataset, dataset);
        return (t) => line(interpolator(t));
      })
      .on('end', () => this.drawCircles(xScale, yScale, dataset));

    this.marginContainer.select('.area')
      .transition()
      .duration(500)
      .ease(d3.easeQuad)
      .attrTween('d', () => {
        const interpolator = d3.interpolateArray(startDataset, dataset);
        return (t) => area(interpolator(t));
      });
  }

  onResize() {
    const dimensions = this.getDimensions();
    if (this.width !== dimensions.width) {
      this.updateChart(_.clone(this.plotData), this.plotLabels, this.categorySetting);
    }
  }
}
