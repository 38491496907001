import { Component, OnInit, Inject } from '@angular/core';
import { VfzeImportStepComponent } from '@app/ps/vfze/components/vfze-import-step/vfze-import-step.component';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'vfze-import-cadastre',
  templateUrl: './vfze-import-cadastre.component.html',
})
export class VfzeImportCadastreComponent extends VfzeImportStepComponent implements OnInit {
  public validated: boolean = null;
  public validationResult: any;
  constructor(
    private restangular: Restangular,
    private errorHandlerService: ErrorHandlerService,
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.restangular.one('vfze/validate-cadastre', this.data.importVfzeId).get().toPromise().then(
      (data: {
        newKatuze: {kod: number, nazev: string}[]
        newParcel: {cislKod: number, katuzeKod: number, idpar: number, parcis: string}[]
      }) => {
        this.validated = (!data.newKatuze || !data.newKatuze.length) && (!data.newParcel || !data.newParcel.length);
        this.validationResult = data;
      }, (r) => {
          this.errorHandlerService.get(r);
          this.backCallback.emit();
       }
    );
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
