﻿import { Component, Input } from "@angular/core";

import { TooltipOwnershipsComponent } from '../tooltip-ownerships/tooltip-ownerships.component';

@Component({
	selector: 'count-ownerships',
	templateUrl: './count-ownerships.component.html',
	styleUrls: ['./count-ownerships.component.scss']
})

export class CountOwnershipsComponent {
	@Input() countOwnerships: number;
	@Input() filter: any;
	tooltipOwnershipsComponent: any = TooltipOwnershipsComponent;
}