import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'occupationCategoryName'
})
@Injectable({
  providedIn: 'root'
})
export class OccupationCategoryNamePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'permanent':
        return 'Trvalý zábor';
      case 'underOneYear':
        return 'Dočasný zábor do jednoho roku';
      case 'overOneYear':
        return 'Dočasný zábor nad jeden rok';
      case 'easement':
        return 'Věcné břemeno';
    }
  }
}
